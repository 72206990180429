import { Document, FullPageError } from 'components';
import {
  useBadgePrintingContext,
  usePageLoadedEvent,
  useVisitContext,
} from 'contexts';
import { useTimedRedirect } from 'hooks';
import { useHistory, useParams } from 'react-router';

export const DocumentAgreement = (): JSX.Element => {
  const history = useHistory();
  const { documentId, visitId: inviteId } =
    useParams<{ documentId: string; visitId: string }>();
  const {
    visit,
    refetch: visitRefetch,
    loading: visitLoading,
  } = useVisitContext();
  const { badgeImageEnabled } = useBadgePrintingContext();

  usePageLoadedEvent('document-agreement', false);
  useTimedRedirect();

  /**
   * Send the user to the checkin screen to check into their visit. If there is no visit
   * available, send the user to the completed screen.
   */
  const completeCheckIn = () => {
    if (badgeImageEnabled) {
      history.push(`/${inviteId}/guest-image-capture`);
    } else if (visit) {
      history.push(`/${inviteId}/check-in`);
    } else {
      history.push(`/completed`);
    }
  };

  if (!visit) {
    return (
      <FullPageError
        onPressRetry={() => {
          visitRefetch && visitRefetch();
        }}
        retryLoading={visitLoading}
      />
    );
  }

  const { arrivalLocation, guest, visitId, customVisitType } = visit;

  return (
    <Document
      visitType={customVisitType}
      locationId={arrivalLocation.id}
      visitId={visitId}
      inviteId={inviteId}
      guestId={guest.id}
      documentId={documentId}
      previousURL={`/${inviteId}/visit-details`}
      nextDocumentURL={`/${inviteId}/document-agreement`}
      guestName={guest.name}
      completeCheckIn={completeCheckIn}
    />
  );
};
