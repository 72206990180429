import { Button, Heading01 } from '@robinpowered/design-system';
import { ContentWrapper, Header } from 'components';
import { useApolloContext, useVisitContext } from 'contexts';
import { useHistory } from 'react-router';
import { useTimedRedirect } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

type ImageUploadStatus = 'success' | 'error';
type ImagePickerStatus = 'cancelled' | 'dismissed';

// Window interface to include our custom actions
declare global {
  interface Window {
    actions?: {
      imageUploaded: (status: ImageUploadStatus) => void;
      imagePickerClosed: (status: ImagePickerStatus) => void;
    };
  }
}

const CAMERA_DELAY_MS = 2000;
const TRANSITION_DELAY_MS = 1000;

export const GuestImageCapture = ({
  isSelfRegistered,
}: {
  isSelfRegistered: boolean;
}): JSX.Element => {
  const { t } = useTranslation('guestImage');
  const history = useHistory();
  const { bearerToken, tenantId } = useApolloContext();
  const { visit } = useVisitContext();
  const [isWaiting, setIsWaiting] = useState(false);
  const [didShowCamera, setDidShowCamera] = useState(false);
  const [didDismissCamera, setDidDismissCamera] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);
  const { id: guestInviteId } = visit ?? {};
  const guestImageEndpoint =
    process.env.REACT_APP_GQL_URL ||
    'https://federation-gateway.robinpowered.com/graphql';

  const handleNextPage = useCallback(() => {
    history.push(
      isSelfRegistered
        ? '/guest-registration/check-in'
        : `/${guestInviteId}/check-in`
    );
  }, [isSelfRegistered, history, guestInviteId]);

  const handleImagePickerClosed = useCallback((status: ImagePickerStatus) => {
    if (status === 'dismissed') {
      setIsWaiting(true);
    }

    if (status === 'cancelled') {
      setIsWaiting(false);
      setDidDismissCamera(true);
    }
  }, []);

  const handleImageUpload = useCallback(
    (status: ImageUploadStatus) => {
      if (status === 'error') {
        setImageUploadError(true);
      }

      if (status === 'success') {
        setTimeout(handleNextPage, TRANSITION_DELAY_MS);
      }
    },
    [handleNextPage]
  );

  // Post the message to the native app to display the camera that will capture the guest image.
  const showCamera = useCallback(() => {
    if (window?.webkit?.messageHandlers?.showCamera) {
      setDidShowCamera(true);
      setImageUploadError(false);

      window.webkit.messageHandlers.showCamera.postMessage(
        {
          guestInviteId: guestInviteId,
          guestImageEndpoint: guestImageEndpoint,
          guestImageEndpointToken: bearerToken,
          tenantId: tenantId,
        },
        '*'
      );
    }
  }, [guestInviteId, bearerToken, tenantId, guestImageEndpoint]);

  useTimedRedirect();

  // When the page loads and the required data is available,
  useEffect(() => {
    if (guestInviteId && !didShowCamera) {
      setTimeout(showCamera, CAMERA_DELAY_MS);
    }
  }, [guestInviteId, bearerToken, tenantId, didShowCamera, showCamera]);

  // Add the custom actions to the window object so we know when the image has been uploaded
  useEffect(() => {
    window.actions = {
      imageUploaded: handleImageUpload,
      imagePickerClosed: handleImagePickerClosed,
    };

    return () => {
      delete window.actions;
    };
  }, [handleImageUpload, handleImagePickerClosed]);

  return (
    <>
      <Header
        nextDisabled={!imageUploadError}
        isLoading={false}
        onPrevClick={() => {
          history.push('/');
        }}
        onNextClick={() => handleNextPage()}
      />

      <ContentWrapper style={{ textAlign: 'center' }}>
        {!isWaiting && !didDismissCamera && !imageUploadError && (
          <Heading01
            role="alert"
            style={{ fontSize: '26px', lineHeight: '36px' }}
          >
            {t('heading')}
          </Heading01>
        )}

        {isWaiting && (
          <Heading01
            role="alert"
            style={{ fontSize: '26px', lineHeight: '36px' }}
          >
            {t('waiting')}
          </Heading01>
        )}

        {!isWaiting && didDismissCamera && !imageUploadError && (
          <MessageWrapper>
            <Heading01
              role="alert"
              style={{
                fontSize: '26px',
                lineHeight: '36px',
                marginBottom: '24px',
              }}
            >
              {t('heading')}
            </Heading01>
            <WelcomeButton
              variant="primary"
              size="large"
              name="check-in"
              onClick={showCamera}
            >
              {t('show_camera')}
            </WelcomeButton>
          </MessageWrapper>
        )}

        {imageUploadError && (
          <MessageWrapper>
            <Heading01
              role="alert"
              style={{
                fontSize: '26px',
                lineHeight: '36px',
                marginBottom: '24px',
              }}
            >
              {t('error')}
            </Heading01>
            <WelcomeButton
              variant="primary"
              size="large"
              name="check-in"
              onClick={showCamera}
            >
              {t('show_camera')}
            </WelcomeButton>
          </MessageWrapper>
        )}
      </ContentWrapper>
    </>
  );
};

export const WelcomeButton = styled(Button)`
  border-radius: 16px;
  height: auto;
  font-size: 26px;
  padding: 20px;
  min-width: 196px;
  width: auto;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
