import checkInCompleted from './checkInCompleted.json';
import checkOut from './checkOut.json';
import guestLookup from './guestLookup.json';
import guestImage from './guestImage.json';
import guestRegistration from './guestRegistration.json';
import registerDevice from './registerDevice.json';
import registerInstructions from './registerInstructions.json';
import visitDetails from './visitDetails.json';
import visitSelection from './visitSelection.json';
import healthCheckpoint from './healthCheckpoint.json';
import healthCheckpointCancellation from './healthCheckpointCancellation.json';
import hostSelect from './hostSelect.json';
import welcome from './welcome.json';
import common from './common.json';
import badgePrint from './badgePrint.json';
import guestRegistrationVisitType from './guestRegistrationVisitType.json';
import guestRegistrationHcpLoading from './guestRegistrationHcpLoading.json';
import documentAgreement from './documentAgreement.json';
import noInternet from './noInternet.json';
import unauthorizedModal from './unauthorizedModal.json';

export const en = {
  common,
  badgePrint,
  checkInCompleted,
  checkOut,
  guestLookup,
  guestImage,
  guestRegistration,
  healthCheckpoint,
  healthCheckpointCancellation,
  hostSelect,
  registerDevice,
  registerInstructions,
  visitDetails,
  visitSelection,
  welcome,
  guestRegistrationVisitType,
  guestRegistrationHcpLoading,
  documentAgreement,
  noInternet,
  unauthorizedModal,
};
