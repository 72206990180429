import { Colors, Heading01, Skeleton } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Field,
  Header,
  Section,
  SectionTitle,
  ContentWrapper,
  InlineError,
  UnauthedServerError,
} from 'components';
import { formatStartTimeMessage } from 'utils';
import { VisitFields } from './components';
import {
  useBadgePrintingContext,
  usePageLoadedEvent,
  useVisitContext,
} from 'contexts';
import {
  GenericHealthCheckpointStatus,
  GuestInviteStatus,
} from '__generated__/globalTypes';
import { useDocumentDetailsWithCustomVisitType, useTimedRedirect } from 'hooks';
import moment from 'moment-timezone';
import { useEffect } from 'react';

const CHECK_IN_WINDOW_SECONDS = 1800; // 30 minutes
const EARLY_CHECK_IN_REDIRECT_SECONDS = 1000 * 10; // 10 seconds

const isInsideCheckInWindow = (startTime: string, timezone: string) => {
  if (!startTime) {
    return false;
  }
  const now = moment();
  const startTimeWithTz = moment.tz(startTime, timezone);
  return startTimeWithTz.diff(now, 'seconds') <= CHECK_IN_WINDOW_SECONDS;
};

const getCheckInTime = (startTime: string, timezone: string) => {
  if (startTime) {
    return moment
      .tz(startTime, timezone)
      .subtract(CHECK_IN_WINDOW_SECONDS, 'seconds')
      .format('h:mm a');
  }
};

export const VisitDetails = (): JSX.Element => {
  const { t } = useTranslation(['common', 'visitDetails']);
  const history = useHistory();
  const { visit, loading, error: visitError, refetch } = useVisitContext();
  const { badgeImageEnabled } = useBadgePrintingContext();
  const {
    arrivalLocation,
    startTime,
    status,
    customVisitType,
    id: guestInviteId,
  } = visit ?? {};
  const isCheckedIn =
    status === GuestInviteStatus.CHECKED_IN ||
    status === GuestInviteStatus.CHECKED_OUT;
  const {
    data,
    loading: documentDetailsLoading,
    error: documentDetailsError,
    refetch: refetchDocumentDetails,
  } = useDocumentDetailsWithCustomVisitType(
    customVisitType ?? '',
    arrivalLocation?.id ?? '',
    guestInviteId ?? ''
  );
  const documentData = data?.visitDocumentWithNextDocumentIdWithCustomVisitType;
  const timezone = arrivalLocation?.timezone ?? moment.tz.guess();
  const isCheckInWindowOpen = isInsideCheckInWindow(startTime, timezone);
  const hasHealthSurvey =
    visit?.healthCheckpointSurveyResponse?.status ===
    GenericHealthCheckpointStatus.INCOMPLETE;

  const getNextButtonRoute = () => {
    if (!isCheckInWindowOpen) {
      return '/';
    } else if (hasHealthSurvey) {
      return 'health-checkpoint';
    } else if (visit && !isCheckedIn && documentData) {
      return 'document-agreement';
    } else if (badgeImageEnabled) {
      return 'guest-image-capture';
    } else {
      return 'check-in';
    }
  };

  usePageLoadedEvent('visit-details', isCheckedIn);
  useTimedRedirect();

  let nextButtonText = t('common:navigation.notify_host');
  if (!isCheckInWindowOpen) {
    nextButtonText = t('common:navigation.done');
  } else if (hasHealthSurvey || documentData || badgeImageEnabled) {
    nextButtonText = t('common:navigation.next');
  }

  // Redirect the user back to the welcome screen after a fixed period if they
  // try to check in before their visit check-in window is open.
  useEffect(() => {
    if (!isCheckInWindowOpen) {
      const timerId = setTimeout(
        () => history.push('/'),
        EARLY_CHECK_IN_REDIRECT_SECONDS
      );
      return () => clearTimeout(timerId);
    }
  }, [isCheckInWindowOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (documentDetailsError) {
    return <UnauthedServerError onPressRetry={refetchDocumentDetails} />;
  }

  return (
    <>
      <Header
        nextDisabled={false}
        isLoading={documentDetailsLoading}
        nextButtonText={nextButtonText}
        onPrevClick={() => history.push('/guest-lookup')}
        onNextClick={() => history.push(getNextButtonRoute())}
      />

      <ContentWrapper>
        {loading && !visitError && <Skeleton m={3} height={18} width="40%" />}

        {visit &&
          (isCheckInWindowOpen ? (
            <>
              <Heading01
                style={{
                  textTransform: 'capitalize',
                  wordWrap: 'break-word',
                }}
              >
                {visit.guest.name} -
              </Heading01>
              <Heading01 mb="4">
                {t('visitDetails:heading', {
                  time: formatStartTimeMessage(
                    visit.startTime,
                    visit.arrivalLocation.timezone
                  ),
                })}
              </Heading01>
            </>
          ) : (
            <Heading01 role="alert" mb="4">
              {t('visitDetails:outside_check_in_window', {
                time: getCheckInTime(visit.startTime, timezone),
              })}
            </Heading01>
          ))}

        <Section boxed>
          <SectionTitle style={{ backgroundColor: Colors.Tan5 }}>
            {t('visitDetails:details_heading')}
          </SectionTitle>
          {loading && !visitError && <Skeleton m={3} height={18} width="40%" />}

          {!loading && visitError && (
            <InlineError
              message={t('visitDetails:visit_error')}
              onRetry={() => refetch?.()}
            />
          )}

          {visit && <VisitFields visit={visit} />}
        </Section>

        {visit?.arrivalInstructions && (
          <Section boxed mt="4">
            <SectionTitle style={{ backgroundColor: Colors.Tan5 }}>
              {t('visitDetails:instructions_heading')}
            </SectionTitle>
            <Field last>{visit.arrivalInstructions}</Field>
          </Section>
        )}
      </ContentWrapper>
    </>
  );
};
