import { useDisplayDetails, useRegisterDisplay } from './hooks';
import {
  BoundedErrorBadge,
  ContentWrapper,
  Field,
  FieldLabel,
  Header,
  InlineError,
  Section,
  SectionTitle,
} from 'components';
import { useHistory, useParams } from 'react-router';
import { DeviceContextProvider, usePageLoadedEvent } from 'contexts';
import { useEffect } from 'react';
import { Colors, Heading01, Skeleton } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';

type QueryParams = {
  organizationId: string;
  registrationKey: string;
};

const RedirectToInstructions = (): null => {
  const { t } = useTranslation('registerDevice');
  const history = useHistory();

  useEffect(() => {
    history.push('/instructions', {
      error: t('alreadyRegisteredError'),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

/**
 * Posts a message to the window using the postMessage API. The native application
 * wrapping this will be listening for this message and will use it to trigger a
 * confirmation message to the user about using network functionality.
 *
 * If this is not running within a native app, this will just be sent out without a consumer.
 */
const testPrint = () => {
  if (window?.webkit?.messageHandlers?.printAction) {
    window.webkit.messageHandlers.testPrint.postMessage({}, '*');
  }
};

export const RegisterDevice = (): JSX.Element => {
  const { organizationId, registrationKey } = useParams<QueryParams>();
  const { t } = useTranslation('registerDevice');
  const history = useHistory();
  usePageLoadedEvent('register-display', false);

  const {
    details,
    loading: detailsLoading,
    error: detailsError,
    refetch,
  } = useDisplayDetails(organizationId, registrationKey);
  const {
    registerDisplay,
    loading: registerLoading,
    error: registerError,
  } = useRegisterDisplay();

  useEffect(() => {
    if (details) {
      testPrint();
    }
  }, [details]);

  if (!detailsLoading && !details) {
    return <RedirectToInstructions />;
  }

  return (
    <DeviceContextProvider org={details?.organization}>
      <Header
        isLoading={registerLoading}
        prevButtonText={t('header.cancel')}
        nextButtonText={t('header.register')}
        onPrevClick={() => history.replace('/')}
        onNextClick={() => registerDisplay(organizationId, registrationKey)}
      />

      {registerError && <BoundedErrorBadge message={t('registerError')} />}

      <ContentWrapper>
        <Heading01 mb="4">{t('heading')}</Heading01>

        <Section boxed>
          <SectionTitle style={{ backgroundColor: Colors.Tan5 }}>
            {t('details.title')}
          </SectionTitle>

          {detailsError && (
            <InlineError message={t('loadingError')} onRetry={refetch} />
          )}

          {!detailsError && (
            <>
              <Field>
                <FieldLabel>{t('details.name')}</FieldLabel>
                {detailsLoading ? (
                  <Skeleton mt={3} height={18} width="40%" />
                ) : (
                  details?.name
                )}
              </Field>
              <Field last>
                <FieldLabel>{t('details.building')}</FieldLabel>
                {detailsLoading ? (
                  <Skeleton mt={3} height={18} width="40%" />
                ) : (
                  details?.location.name
                )}
              </Field>
            </>
          )}
        </Section>
      </ContentWrapper>
    </DeviceContextProvider>
  );
};
